import React from 'react';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SmartLink from 'components/smartLink';
import styles from './404.module.scss';

class Error404 extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');

    return (
      <>
        <Helmet title={siteTitle} />
        <section className={styles.container}>
          <h1 className={styles.title}>404</h1>
          <div className={styles.subtitle}>Sorry folks, great content shouldn't be this hard to find.</div>
          <SmartLink className={styles.button} to='/'>
            Take me home
          </SmartLink>
        </section>
      </>
    );
  }
}

export default Error404;
